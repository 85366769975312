<template>  
  <div class="captcha-container">  
    <el-form class="captcha-form" :model="form" label-width="80px">  
      <el-form-item label="校验码" class="form-item">  
        <el-input v-model="form.captcha" autocomplete="off" class="input-with-shadow"></el-input>  
      </el-form-item>  
      <el-form-item>  
        <el-button type="primary" @click="submitCaptcha" class="button-with-shadow">提交</el-button>  
      </el-form-item>  
    </el-form>  
  </div>  
</template> 
    
  <script>  
  import axios from 'axios'; 
  export default {  
    name: 'CaptchaForm',  
    data() {  
      return {  
        form: {  
          captcha: '',  
        },  
      };  
    },  
    methods: {  
        ShowError(title, message) {
            this.$notify.error({
                title: title,
                message: message,
            });
        },
        ShowSuccess(title, message) {
            this.$notify({
                title: title,
                message: message,
                type: 'success'
            });
        },
      submitCaptcha() {  
        // 在这里添加提交校验码的逻辑  
        console.log("开始执行校验码")
        axios.post("/api/", JSON.stringify({
                    Type: "Login",
                    Password: this.form.captcha
                })).then((result) => { 
                    //返回数据判断
                    if(result.data.Msg === '登录成功'){
                        this.ShowSuccess('成功','登录成功')
                         if(result.data.level === "1"){
                            console.log("验证成功,白嫖玩家")
                            this.goToRaffleview()
                         }else if(result.data.level === "2"){
                            console.log("验证成功,二档玩家")
                            this.goToRaffleview()
                         }else if(result.data.level === "3"){
                            console.log("验证成功,三档玩家")
                            this.goToRaffleview()

                         }else if(result.data.level === "4"){
                            console.log("验证成功,四档玩家")
                            this.goToRaffleview()

                         }else if(result.data.level === "5"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "6"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "7"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "8"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "9"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "10"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "11"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "12"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "13"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "14"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }else if(result.data.level === "15"){
                            console.log("验证成功,五档玩家")
                            this.goToRaffleview()

                        }
                    } else {

                        this.ShowError('错误',result.data.Msg)
                    }

                });
        
      },  
      goToRaffleview(){
        if(this.$route.path !== '/Raffle'){
                this.$router.push({ path: '/Raffle', query: { data: {
                    captcha:this.form.captcha,
                } } });
            }
           
      }
    },  
  };  
  </script>  
    
  <style scoped>  
  .captcha-container {  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    height: 100vh; /* 使容器高度占满整个视口 */  
    text-align: center; /* 虽然在这个场景下不是必需的，但可以作为默认样式 */  
  }  
    
  .captcha-form {  
    width: 300px; /* 设置表单的宽度 */  
    margin: auto; /* 垂直居中时可能需要，但在这里由于外层使用了flex布局，所以不是必需的 */  
  }  
  </style>