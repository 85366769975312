var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("三国云梦录")]),_c('el-card',{staticClass:"lottery-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"el-icon-present"}),_c('span',[_vm._v("抽奖说明")])]),_c('div',{staticClass:"lottery-content"},[_c('h4',[_vm._v("奖品列表")]),_c('ul',[_c('li',[_vm._v("6元游戏内等价礼包")]),_c('li',[_vm._v("12元游戏内等价礼包")]),_c('li',[_vm._v("30元游戏内等价礼包")]),_c('li',[_vm._v("98元游戏内等价礼包")]),_c('li',[_vm._v("198元游戏内等价礼包")]),_c('li',[_vm._v("328元游戏内等价礼包")]),_c('li',[_vm._v("648元游戏内等价礼包")]),_c('li',[_vm._v("1298元游戏内等价礼包")]),_c('li',[_vm._v("1988元游戏内等价礼包")]),_c('li',[_vm._v("3288元游戏内等价礼包")]),_c('li',[_vm._v("6888元游戏内等价礼包")]),_c('li',[_vm._v("8888元游戏内等价礼包")]),_c('li',[_vm._v("18888元游戏内等价礼包")]),_c('li',[_vm._v("99999元游戏内等价礼包")])]),_c('h4',[_vm._v("发奖方式")]),_c('p',[_vm._v("尊贵的玩家，抽中后请您第一时间截图抽中界面，然后联系我们的VIP客服进行领取")])])]),_c('el-form',{ref:"Raffleinfo",staticClass:"demo-ruleForm",attrs:{"model":_vm.Raffleinfo}},[_c('el-form-item',{attrs:{"label":"玩家昵称","prop":"name","rules":[
            { required: true, message: '昵称不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.name),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "name", _vm._n($$v))},expression:"Raffleinfo.name"}})],1),_c('el-form-item',{attrs:{"label":"玩家ID","prop":"ID","rules":[
            { required: true, message: 'ID不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.ID),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "ID", _vm._n($$v))},expression:"Raffleinfo.ID"}})],1),_c('el-form-item',{attrs:{"label":"玩家区服","prop":"district","rules":[
            { required: true, message: '区服不能为空' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.Raffleinfo.district),callback:function ($$v) {_vm.$set(_vm.Raffleinfo, "district", _vm._n($$v))},expression:"Raffleinfo.district"}})],1),_c('el-form-item',[_c('el-button',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"element-loading-text":"努力抽奖中·····","type":"primary"},on:{"click":function($event){return _vm.submitForm('Raffleinfo')}}},[_vm._v("开始抽奖")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }